import DOMPurify from "dompurify";

export default {
  methods: {
    sanitizeContent(html) {
      const sanitized = DOMPurify.sanitize(html, {
        ALLOWED_TAGS: [
          "a",
          "b",
          "i",
          "p",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "em",
          "u",
          "span",
          "strong",
        ],
        ALLOWED_ATTR: ["class", "style", "href", "target", "rel"],
      });
      return sanitized;
    },
  },
};
